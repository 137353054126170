.Mulish {
  font-weight: 400;
  font-family: "Raleway";
}
.eifeil-img-mobile {
  display: none;
}
.ant-badge-status-dot {
  border: 1px solid rgba(43, 43, 43, 0.768);
  width: 11px !important;
  height: 11px!important;
 
}
.menu {
  margin: auto;
  text-align: center !important;
  color: white !important;
  padding-top: 16px;background-color: rgb(0, 70, 142) !important;
}
.bm-item, .sub-item {
  color: white !important;
  font-size: 20px;
  color: black;
  display: block;
  padding: 14px 28px;
  cursor: pointer;
}
.menu-wrap {color: white !important;
  position: fixed;
  top: 0;
  margin-left: -0.5em !important;
  bottom: 0;
  z-index: 1100;
  width: 100%;
  height: 100%;
  transition: all .5s ease 0s;
  background-color: rgb(0, 70, 142) !important;
  
}

.active .badge-class{border: 1px solid rgb(49, 104, 160); 
  margin-right: -0.08em;
  width: 20.4px!important;
  height: 20.4px!important;
  border-radius: 10em;width: 1.5em;
  height: 1.5em; transform: 1s;}

.ant-badge-status {
  margin: 0.18em !important;
  margin-top: 0.25em !important;
  text-align: center;
  display: grid !important;
  border-radius: 10em !important;
  padding: 0.1em !important;
  line-height: inherit;
  vertical-align: baseline;
}

.active {
  color: red !important;
}
.active .ant-badge-status-dot{
  background: rgb(0, 0, 0) !important;
}

.ant-badge-status-dot:hover {
border: 1px solid rgb(49, 104, 160);
background-color: rgb(26, 27, 27)!important;
  width: 12px !important;
  height: 12px!important;
 
}
.react-responsive-modal-modal {
  max-width: 800px;
  display: inline-block;
  text-align: center !important;
  vertical-align: middle;
  background: #ffffff !important;
  box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
  margin: 1.2rem;
  padding: 1.2rem;
  position: relative;
  border-radius: 1.5em;
  overflow-y: auto;
}
.react-responsive-modal-closeButton {
  fill: rgb(0, 0, 0) !important;
}
.bm-item,
.sub-item {
  font-size: 20px;
  color: black;
  display: block;
  padding: 14px 28px;
  cursor: pointer;
}
.mulish-header {
  font-weight: 400;
  font-family: "Raleway";
}
.mulish-paragraph {
  font-weight: 400;
  font-family: "Raleway";
}
.ant-card-meta-title {
  overflow: hidden;
  color: #2D6896 !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: "Raleway";
}
.mulish-paragraph-small {
  font-weight: 400;
  font-family: "Raleway";
}
.Mulish-center {
  font-weight: 400;
  font-family: "Raleway";
}
.Mulish-product-range {
  font-weight: 400;
  font-family: "Raleway";
}
.Mulish-footer {
  font-weight: 400;
  font-family: "Raleway";
}
.Poppins {
  font-family: "Raleway";
}
.Chela {
  font-family: "Raleway";
}
.Caveat {
  font-family: "Raleway";
}
.Grace {
  font-family: "Raleway";
}
.Condiment {
  font-family: "Raleway";
}
.category-card-hover:hover {
  transition: 0.2s;
  filter: invert(1.2);
}
#nav-toggle span {
  display: none;
}
.mobile-display {
  display: none;
}
@media only screen and (max-width: 770px) {
  .desktop-display {
    display: none;
  }
  .category-card-hover {
    width: 93vw !important;
  }
  .mobile-display {
    display: inline !important
  }
  .header-logo {
    width: 4em !important;
    margin-left: 0.5em;
  }
  #nav-toggle span {
    position: absolute;
    right: 1em;
    top: 30px;
  }

  #nav-toggle span,
  #nav-toggle span:before,
  #nav-toggle span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 5px;
    width: 35px;
   
    background: #ffffff;
    position: absolute;
    display: block;
    content: "";
  }
  #nav-toggle span:before {
    top: -10px;
  }
  #nav-toggle span:after {
    bottom: -10px;
  }
  .mulish-header-mobile {
    text-align: center;
    margin-top: 15vh !important;
    line-height: 1;
    margin: auto;
    margin-left: 0.3em !important;
    margin-right: 0.3em !important;
    font-size: 13vw !important;
    font-weight: 400;
    font-family: "Raleway";
  }
  .mulish-header {
    text-align: center;
    margin-top: 15vh !important;
    line-height: 1;
    font-size: 10vh !important;
    font-weight: 400;
    font-family: "Raleway";
  }
  .mulish-paragraph {
    font-size: 5vh !important;
    margin-top: 0.2em !important;
    text-align: center;
    line-height: 1;
    font-weight: 400;
    font-family: "Raleway";
  }
  .home-col {
    height: 100vh;
  }
  .mulish-paragraph-small {
    text-align: center;
    font-weight: 400;
    font-family: "Raleway";
  }
  .home-button {
    margin-top: 0.5em !important;
    text-align: center;
    margin: auto;
    line-height: 1;
    font-weight: 400;
    font-family: "Raleway";
  }
  .home-img {
    display: none;
  }
  .about-row {
    margin-top: 0em !important;
    margin: auto;
    text-align: center;
  }
  .about-row-cert {
    margin: auto;
    margin-top: 2em !important;
    text-align: center;
  }
  .Mulish-center {
    padding-right: 0em !important;
    font-weight: 400;
    font-family: "Raleway";
  }
  .Mulish-product-range {
    margin-top: -1.5em !important;
    font-weight: 400;
    font-family: "Raleway";
  }
  .products-img {

    margin-top: 0em !important;
  }
  .products-img-1 {
    margin-top: -3em !important;
  }
  .view-more-button {
    margin-top: -3em !important;
  }
  .hori-line {
    display: none;
  }
  .gallery-banner {
    text-align: center;
  }
  .gallery-banner-img {
    width: 100%;
  }
  .gallery-container {
    margin-top: 3em !important;
  }
  .footer-div {
    height: 45em !important;
  }
  .footer-logo {
    text-align: center;
  }
  .Mulish-footer {
    font-weight: 400;
    padding-right: 0 !important;
    font-family: "Raleway";
  }
  .footer-links-company {
    width: 85vw !important;
    text-align: center !important;
  }
  .contact-image {
    width: 100vw !important;
    margin-top: 7em !important;
  }
  .gallery-paragraph {
    padding: 0em !important;
    padding-top: 1em !important;
  }
  .Mulish-hidden {
    display: none;
  }
  .eifeil-img-mobile {
    display: inline;
  }
  .eifeil-img {
    display: none;
  }
  .other-button {
    margin-top: 1.5em !important;
    margin-bottom: 1.5em !important;
  }
}
